import React, { createContext } from "react";
import { injectIntl } from "react-intl";
import Config from '../../models/Config';
import IntlMessage from "../../models/IntlMessage";

const ConfigContext = createContext({});

function getIntlMessageFromIntlId(id: string): IntlMessage {
  return {
    id,
    defaultMessage: id
  };
}

function getIntlIdFromPath(path: string) {
  return path
    .replace(/(\/|\.|\-|\_\#)/gi, ' ')
    .trim()
    .split(' ')
    .filter(v => !(typeof v !== 'string' || v.length < 1))
    .join('_')
    .toUpperCase();
}

const AUTO_INTL_KEY = '$AUTO';

function getConfigWithResolvedIntl(config: Config, intl): Config {
  if (config.metadata.isResolved) return config;

  const configCopy = {...config};

  function setIntlPathsInObjWithPrefix(prefix: string, obj: Object): void {
    Object
      .keys(obj)
      .forEach(key => {
        if (typeof obj[key] === 'string' && obj[key].toUpperCase() === AUTO_INTL_KEY) {
          obj[key] = intl.formatMessage(getIntlMessageFromIntlId(getIntlIdFromPath(`${prefix}_${key}`)));
        } else if (typeof obj[key] === 'string') {
          obj[key] = intl.formatMessage(getIntlMessageFromIntlId(obj[key]));
        }

        if (typeof obj[key] === 'object') {
          const maybePrefix = prefix.length > 0 ? prefix + '_' : '';
          setIntlPathsInObjWithPrefix(`${maybePrefix}${key}`, obj[key]);
        }
      });
  }

  setIntlPathsInObjWithPrefix('', configCopy);

  configCopy.metadata.isResolved = true;

  return configCopy;
}

export function withConfig(WrappedComponent: any): (props: any) => any {
  return injectIntl((props: any) => {
    return (
      <ConfigContext.Consumer>
        {config => <WrappedComponent config={getConfigWithResolvedIntl(config as Config, props.intl)} {...props} />}
      </ConfigContext.Consumer>
    )
  });
}

export default ConfigContext;
