import { Cloudinary } from "@cloudinary/url-gen";

export default function resolveImageSrc(src: string): string {
  if (!src) return src;
  if (src.startsWith("http")) return src;

  return new Cloudinary({ cloud: { cloudName: "camquat" } })
    .image(src)
    .setDeliveryType('upload')
    .toURL();
}
