import React, { ReactNode } from "react";
import getScopedClassName from "../../utils/getScopedClassName";
import "./style.scss";

export enum HeadingLevel {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3'
}

export enum HeadingStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECTION = 'section',
}

interface HeadingProps {
  level: HeadingLevel;
  style: HeadingStyle;
  children: ReactNode;
}

const c = getScopedClassName('Heading');

export default function Heading({
  level,
  style,
  children,
}: HeadingProps) {
  const Level = level;

  return (
    <Level className={c(style)}>
      {children}
    </Level>
  );
}
