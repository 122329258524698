import React from "react";
import Heading, { HeadingLevel, HeadingStyle } from "../../../lib/components/Heading";
import Intro from "../../../lib/components/Intro";
import Content from "../../../lib/models/Content";
import getScopedClassName from "../../../lib/utils/getScopedClassName";
import resolveImageSrc from "../../../lib/utils/resolveImageSrc";
import './style.scss';

interface CollectionCardProps extends Content {}

const c = getScopedClassName("CollectionCard");

export default function CollectionCard({
  title,
  description,
  imageUrl,
  route
}: CollectionCardProps) {
  const resolvedSrc = resolveImageSrc(imageUrl);

  return (
    <li className={c('root')}>
      <a href={route} className={c('link')}>
        <div
          className={c('background')}
          style={{ backgroundImage: `url('${resolvedSrc}')` }}
        />
        <div className={c('title')}>
          <Heading 
            level={HeadingLevel.H3} 
            style={HeadingStyle.SECONDARY}
          >
            {title}
          </Heading>
        </div>
        <div className={c('description')}>
          <Intro>{description}</Intro>
        </div>
      </a>
    </li>
  );
}