import React, { ReactNode } from "react";
import getScopedClassName from "../../utils/getScopedClassName";
import "./style.scss";

interface ScreenProps {
  children: ReactNode;
}

const c = getScopedClassName('Screen');

export default function Screen({
  children
}: ScreenProps) {
  return (
    <div className={c('root')}>
      {children}
    </div>
  )
}