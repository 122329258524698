import React from "react";
import getScopedClassName from "../../utils/getScopedClassName";
import "./style.scss";

const c = getScopedClassName("Footer");

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <div className={c('root')}>
      <div className={c('container')}>
        <span className={c('copyright')}>{`Copyright ${year} Benjamin Robinson (Paul Arbre)`}</span>
      </div>
    </div>
  );
}
