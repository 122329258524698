import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import config from './content/config.json';
import messages from './content/messages.json';

const root = document.getElementById('root');

ReactDOM.render(
  <App config={config} messages={messages} />, 
  root
);