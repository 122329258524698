import React from 'react';
import Config from '../../../lib/models/Config';
import { ProcessStatus } from '../../../lib/models/Status';
import getScopedClassName from "../../../lib/utils/getScopedClassName";
import Button, { ButtonStyle, Icon } from '../../../lib/components/Button';
import { withConfig } from '../../../lib/components/ConfigContext';
import Logo from '../Logo';
import "./style.scss";

interface HeaderProps {
  onMenuOpen?(): void;
  config: Config;
}

const c = getScopedClassName('Header');

function Header({
  onMenuOpen = () => {},
  config
}: HeaderProps) {
  return (
    <header className={c('root')}>
      <div className={c('container')}>
        <div className={c('logo')}>
          <Logo href={'/'}>{config.metadata.title}</Logo>
        </div>
        <div className={c('menu-button')}>
          <Button
            buttonStyle={ButtonStyle.BUTTON}
            status={ProcessStatus.READY}
            onClick={onMenuOpen}
            icon={Icon.MENU}
          >
            {'Menu'}
          </Button>
        </div> 
      </div>
    </header>
  )
}

export default withConfig(Header);
