import React from "react";
import Config from "../../models/Config";
import { withConfig } from "../ConfigContext";
import ContentRenderer from "../ContentRenderer";
import { Renderer } from "../RenderersContext";

interface RefRendererProps {
  config: Config;
  $ref: string;
  renderer?: Renderer;
}

function isRefRenderable($ref: string, config: Config) {
  return typeof $ref === 'string' &&
      $ref.length > 0 &&
      typeof config === 'object' &&
      $ref in config;
}

function RefRenderer({
  config,
  $ref,
  renderer
}: RefRendererProps) {
  if (!isRefRenderable($ref, config)) return null;

  if (renderer) {
    const OverrideRenderer = renderer;

    return (
      <OverrideRenderer {...config[$ref]} />
    )
  }
  return (
    <ContentRenderer content={config[$ref]} />
  );
}

export default withConfig(RefRenderer);
