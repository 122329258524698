import React, { Component, createRef, ReactNode } from "react";
import { MenuPosition } from './MenuPosition';
import getScopedClassName from '../../utils/getScopedClassName';
import onClickInside from '../../utils/onClickInside';
import './style.scss';

interface MenuProps {
  children?: ReactNode;
  drawerPosition: MenuPosition;
  isOpen: boolean;
  onClose: () => void;
}

interface MenuState {
  isCloseable: boolean;
}

const c = getScopedClassName('Menu');

export default class Menu extends Component<MenuProps, MenuState> {

  private drawer;

  constructor(props: MenuProps) {
    super(props);

    this.drawer = createRef();
    this.state = {
      isCloseable: props.isOpen
    }
  }

  componentDidMount() {
    onClickInside(this.drawer.current, (isInside: boolean) => {
      if (!isInside && this.state.isCloseable) {
        this.props.onClose();
      }
    });
  }

  componentDidUpdate(prevProps: MenuProps) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      this.setState({ isCloseable: false }, () => {
        setTimeout(() => {
          this.setState({ isCloseable: true });
        }, 300);
      });
    } 
  }

  render() {
    const { 
      children,
      drawerPosition, 
      isOpen } = this.props;

    const openClass = isOpen ? c('open') : '';
    const rootClass = `${c('root')} ${openClass}`;
    const drawerPositionClass = c(drawerPosition);
    const drawerClass = `${c('drawer')} ${drawerPositionClass}`;

    return (
      <div className={rootClass}>
        <div className={drawerClass} ref={this.drawer}>
          <div className={c('contents')}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
