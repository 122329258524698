import React, { createContext, ElementType, FunctionComponent, ReactNode } from "react";
import Config from "../../models/Config";
import Content from "../../models/Content";

export type Renderer = ElementType<Content> | FunctionComponent<Content>;

export interface Renderers {
  DefaultRenderer: Renderer;
  [key: string]: Renderer;
}

export const RenderersContext = createContext<Renderers>({ DefaultRenderer: () => null });

export function withRenderers(WrappedComponent: any) {
  return (props) => (
    <RenderersContext.Consumer>
      {renderers => <WrappedComponent {...props} renderers={renderers} />}
    </RenderersContext.Consumer>
  )
}

export function getRendererFromKey(key: string, renderers: Renderers): Renderer {
  if (typeof renderers !== 'object') return () => null;
  const isRendererUndefined = (typeof key !== 'string' || key.length < 1 || !(key in renderers));
  if (isRendererUndefined && !renderers.DefaultRenderer) return () => null;
  if (isRendererUndefined) return renderers.DefaultRenderer;
  return renderers[key];
}

export function getRendererFromContent(content: Content, renderers: Renderers): Renderer {
  if (typeof content !== 'object') return () => null;
  return getRendererFromKey(content.renderer, renderers);
}

export function getReferencedContent(content: Content, config: Config): Content {
  if (typeof content !== 'object') return null;
  if (typeof content.$ref !== 'string' || content.$ref.length < 1) return content;

  const reference = content.$ref;
  const referenceParts = reference.split('.');
  const referenceContent: Object = referenceParts.reduce<Object>((acc, cur) => {
    if (typeof acc !== 'object' || !(cur in acc)) return null;
    return acc[cur];
  }, config || null);

  return {
    ...referenceContent,
    ...content
  };
}
