import React, { ReactNode, useEffect, useState } from "react";
import nonEmptyString from "../../utils/nonEmptyString"
import { ProcessStatus } from "../../models/Status";
import getScopedClassName from "../../utils/getScopedClassName";
import "./style.scss";

export enum ButtonStyle {
  LINK = 'link',
  BUTTON = 'button',
  ICON = 'icon',
}

export enum Icon {
  MENU = 'icon-menu',
  CLOSE = 'icon-close',
  DONE = 'icon-done'
}

export interface ButtonProps {
  buttonStyle: ButtonStyle;
  children?: ReactNode;
  href?: string;
  icon?: Icon;
  isDisabled?: boolean;
  onChangeStatus?(status: ProcessStatus): void;
  onClick?(): void;
  progress?: number;
  timeToReset?: number;
  status?: ProcessStatus;
}

const c = getScopedClassName('Button');

export default function Button({
  buttonStyle = ButtonStyle.BUTTON,
  children = null,
  href = '',
  icon = null,
  isDisabled = false,
  onChangeStatus = () => {},
  onClick = () => {},
  progress = 0,
  timeToReset = 1000,
  status = ProcessStatus.READY
}: ButtonProps) {
  const [_status, setStatus] = useState(status);
  
  useEffect(() => {
    if (_status !== status) {
      onChangeStatus(status);
      setStatus(status);
      return;
    }
  }, [status]);

  useEffect(() => {
    if (_status !== status) {
      onChangeStatus(_status);
      return;
    }
  }, [_status]);

  function getProgressStyle() {
    let _progress = 0;

    switch (_status) {
      case ProcessStatus.RUNNING:
        _progress = progress;
        break;
      case ProcessStatus.SUCCEEDED:
        _progress = 100;
        break;
      default:
        _progress = 0;
        break;
    }

    let progressStyle = {
      width: `${_progress}%`, 
      opacity: `${_progress / 100}`,
      filter: null
    };

    if (_status !== ProcessStatus.SUCCEEDED) {
      progressStyle.filter = `grayscale(100%)`;
    }

    return progressStyle;
  }

  switch(_status) {
    case ProcessStatus.SUCCEEDED:
    case ProcessStatus.FAILED:
      if (timeToReset > 0) {
        setTimeout(() => setStatus(ProcessStatus.READY), timeToReset);
      }
      break;
  }

  const hasIcon = nonEmptyString(icon);
  const classNames = [c('root'), c(_status)];
  const progressStyle = getProgressStyle();
  const Element = nonEmptyString(href) ? 'a' : 'button';

  if (hasIcon) {
    classNames.push(c('icon'));
    classNames.push(c(icon));
  } else {
    classNames.push(c(buttonStyle));
  }

  return (
    <Element
      className={classNames.join(' ')}
      disabled={isDisabled || status !== ProcessStatus.READY}
      href={href}
      onClick={() => onClick()}
    >
      {!hasIcon && <span className={c('progress')} style={progressStyle} />}
      {hasIcon ? null : children}
    </Element>
  );
}