import React, { useEffect, useState } from 'react';
import {
  Route, Switch
} from "react-router-dom";
import Config from '../../models/Config';
import Content from '../../models/Content';
import { withConfig } from '../ConfigContext';
import ContentRenderer from '../ContentRenderer';
import { withRenderers } from '../RenderersContext';

function sortRoutes(a: Content, b: Content): number {
  const aSlashes = a?.route.split('/').length;
  const bSlashes = b?.route.split('/').length;

  if (aSlashes > bSlashes) return -1;
  if (aSlashes < bSlashes) return 1;
  if (aSlashes === bSlashes) {
    if (a?.route.length > b?.route.length) return -1;
    if (a?.route.length < b?.route.length) return 1;
    return 0;
  }
}

interface RoutesRendererProps {
  config: Config;
}

export const ROUTE_NONE = "NONE";

export function RoutesRenderer({ 
  config
}: RoutesRendererProps) {

  // Set the routes once
  const [routes, setRoutes] = useState([]);
  useEffect(() => {
    setRoutes(Object
      .keys(config)
      .map(key => config[key])
      .filter(content => content?.route !== ROUTE_NONE)
      .sort(sortRoutes));
  }, []);

  return (
    <Switch>
      {routes.map(route => {
        return (
          <Route path={route?.route} key={route?.route}>
            <ContentRenderer content={route} />
          </Route>
        );
      })}
    </Switch>
  )
}

export default withConfig(withRenderers(RoutesRenderer));