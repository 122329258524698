import React from 'react';
import Content from '../../../lib/models/Content';
import getScopedClassName from '../../../lib/utils/getScopedClassName';
import ContentRenderer from '../../../lib/components/ContentRenderer';
import Page from '../../../lib/components/Page';
import Painting, { PaintingProps } from '../Painting';
import './style.scss';

interface PageCollectionProps extends Content {}

const c = getScopedClassName('PageCollection');

function Item(props: PaintingProps) {
  return (
    <li className={c('item')}>
      <Painting {...props} />
    </li>
  );
}

export default function PageCollection({
  content,
  description,
  body,
  title
}: PageCollectionProps) {
  return (
    <Page
      body={body}
      title={title}
      description={description}
    >
      <ul className={c('items')}>
        <ContentRenderer 
          renderer={Item} 
          content={content}
        />
      </ul>
    </Page>
  );
}
