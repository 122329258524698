import React, { useState } from 'react';
import {
  BrowserRouter as Router
} from "react-router-dom";
import Header from '../../../lib/components/Header';
import Screen from '../../../lib/components/Screen';
import Menu from '../../../lib/components/Menu';
import { MenuPosition } from '../../../lib/components/Menu/MenuPosition';
import Page from '../../../lib/components/Page';
import PageHome from '../PageHome';
import CollectionCard from '../CollectionCard';
import PageCollection from '../PageCollection';
import PageCollections from '../PageCollections';
import Painting from '../Painting';
import MenuLink from '../MenuLink';
import MenuList from '../MenuList';
import Footer from "../../../lib/components/Footer";
import ConfigContext from '../../../lib/components/ConfigContext';
import RoutesRenderer from '../../../lib/components/RoutesRenderer';
import { Renderers, RenderersContext } from '../../../lib/components/RenderersContext';
import RefRenderer from '../../../lib/components/RefRenderer';
import { IntlMessagesByLocaleProvider } from '../../../lib/components/IntlMessagesByLocaleProvider';
import PriceCoefficientProvider from '../PriceCoefficientContext';

export interface AppProps {
  config: any;
  messages: any;
}

const renderers: Renderers = {
  DefaultRenderer: Page,
  CollectionCard,
  Page,
  PageHome,
  PageCollection,
  PageCollections,
  Painting,
  MenuLink,
  MenuList
}

export default function App({ config, messages }: AppProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <IntlMessagesByLocaleProvider messagesByLocale={messages}>
      <ConfigContext.Provider value={config}>
        <PriceCoefficientProvider>
          <RenderersContext.Provider value={renderers}>
            <Router>
              <Menu 
                drawerPosition={MenuPosition.RIGHT}
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
              >
                <RefRenderer $ref={'menu'} />
              </Menu>
              <Header onMenuOpen={() => setIsMenuOpen(true)} />
              <Screen>
                <RoutesRenderer />
              </Screen>
              <Footer />
            </Router>
          </RenderersContext.Provider>
        </PriceCoefficientProvider>
      </ConfigContext.Provider>
    </IntlMessagesByLocaleProvider>
  );
}
