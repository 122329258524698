import React from 'react';
import getSafeHtml from '../../utils/getSafeHtml';
import getScopedClassName from "../../utils/getScopedClassName";

export interface MarkdownProps {
  children: string;
}

const c = getScopedClassName('Markdown');

export default function Markdown({
  children = ''
}) {
  return (
    <div 
      className={c('root')} 
      dangerouslySetInnerHTML={{__html: getSafeHtml(children)}}
    />
  );
}
