import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from '../../../lib/components/Modal';
import Product from '../../../lib/models/Product';
import getScopedClassName from "../../../lib/utils/getScopedClassName";
import resolveImageSrc from '../../../lib/utils/resolveImageSrc';
import { PriceCoefficientContext } from '../PriceCoefficientContext';
import "./style.scss";

const c = getScopedClassName('Painting');

export interface PaintingProps extends Product {
  priceCoefficient: number;
}

interface ImageDimensions {
  width: number;
  height: number;
}

export default function Painting({
  dimensions,
  isSold,
  material,
  imageUrl,
  title,
  price,
}: PaintingProps) {
  const resolvedSrc = resolveImageSrc(imageUrl);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [originalImageDimensions, setOriginalImageDimensions] = useState<ImageDimensions>({ width: 0, height: 0 });
  const [modalImageDimensions, setModalImageDimensions] = useState<ImageDimensions>({ width: 0, height: 0 });
  const priceCoefficient = useContext(PriceCoefficientContext);
  const imageRef = useRef(null);
  // const calculatedPrice = (price?.value || 0) + (dimensions.length * dimensions.width * priceCoefficient);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      imageRef.current.classList.add(c('image-loaded'));
      imageRef.current.src = resolvedSrc;
      setOriginalImageDimensions({
        width: image.width,
        height: image.height  
      });
    };
    image.src = resolvedSrc;
  }, []);

  function updateModalImageDimensions() {
    const windowDimensions: ImageDimensions = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    let width = originalImageDimensions.width, height = originalImageDimensions.height;
    const imageRatio = originalImageDimensions.width / originalImageDimensions.height;

    if (windowDimensions.width <= originalImageDimensions.width && windowDimensions.height > windowDimensions.width / imageRatio) {
      width = windowDimensions.width;
      height = windowDimensions.width / imageRatio;
    } else if (windowDimensions.height <= originalImageDimensions.height && windowDimensions.width > windowDimensions.height * imageRatio) {
      width = windowDimensions.height * imageRatio;
      height = windowDimensions.height;
    }

    setModalImageDimensions({
      width: width,
      height: height
    });
  }

  function handleOpenModal() {
    updateModalImageDimensions();
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  return (
    <div className={c('root')}>
      <div className={c('image-container')}>
        <img className={c('image')} ref={imageRef} onClick={handleOpenModal} />
      </div>
      <div className={c('info')}>
        <div className={c('details')}>
          <h3 className={c('title')}>
            {isSold && <span className={c('sold')} />}
            {title || 'Untitled'}
          </h3>
          <p className={c('dimensions')}>
            {`${dimensions.width}${dimensions.measurementUnit} x ${dimensions.length}${dimensions.measurementUnit}, ${material}`}
          </p>
          {/* <div className={c('purchase')}>
            <PopupButton
              buttonContent={'Inquire'}
              isDisabled={isSold}
            >
              <InquiryForm onSend={(payload) => console.log(payload)} product={{dimensions,isSold,material,imageUrl,title,price}} />
            </PopupButton>
          </div> */}
        </div>
      </div>
      <Modal 
        isOpen={isModalOpen} 
        onClose={handleCloseModal}
      >
        <div className={c('modal-contents')} style={{ ...modalImageDimensions }}>
          <div className={c('no-frame')}>
            <img className={`${c('image')} ${c('image-loaded')}`} src={resolvedSrc} />
          </div>
        </div>
      </Modal>
    </div>
  )
}