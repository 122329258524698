import React from "react";
import Content from "../../../lib/models/Content";
import getScopedClassName from "../../../lib/utils/getScopedClassName";
import ContentRenderer from "../../../lib/components/ContentRenderer";
import "./style.scss";

type MenuListProps = Content;

const c = getScopedClassName('MenuList');

function isRenderable(content: Content | Content[]) {
  return Array.isArray(content) && content.length > 1;
}

export default function MenuList({
  content,
}: MenuListProps) {
  if (!isRenderable(content)) return null;

  console.log('Go ahead and render');

  return (
    <ul className={c('root')}>
      {(content as Array<Content>).map(item => (
        <li 
          key={JSON.stringify(item)} 
          className={c('item')}
        >
          <ContentRenderer 
            content={item} 
          />
        </li>
      ))}
    </ul>
  );
}
