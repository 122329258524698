import React, { useEffect, useState } from "react";
import { ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { IntlMessagesByLocale } from "../../models/IntlMessage";
import { withConfig } from "../ConfigContext";

export interface IntlMessagesByLocaleProviderProps {
  children?: ReactNode;
  messagesByLocale: IntlMessagesByLocale;
}

export function IntlMessagesByLocaleProvider({
  children,
  messagesByLocale
}: IntlMessagesByLocaleProviderProps) {

  const [locale, setLocale] = useState("en-US");

  function setUserLocale() { 
    const locales = window?.navigator?.languages;

    for (let i = 0; i < locales.length; i++) {
      if (typeof messagesByLocale[locales[i]] === 'object') {
        setLocale(locales[i]);
        break;
      }
    }
  }

  useEffect(() => setUserLocale(), []);

  return (
    <IntlProvider 
      locale={locale} 
      messages={messagesByLocale[locale] as any}
    >
      {children}
    </IntlProvider>
  );
}

export default withConfig(IntlMessagesByLocaleProvider);
