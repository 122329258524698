import React from "react";
import Button, { ButtonStyle } from "../../../lib/components/Button";
import ContentRenderer from "../../../lib/components/ContentRenderer";
import Page from "../../../lib/components/Page";
import Content from "../../../lib/models/Content";
import getScopedClassName from "../../../lib/utils/getScopedClassName";
import "./style.scss";

interface PageHomeProps extends Content {
  content: Content;
}

const c = getScopedClassName('PageHome');

export default function PageHome({
  body,
  description,
  title,
  content
}: PageHomeProps) {
  return (
    <Page
      body={body}
      description={description}
      title={title}
    >
      {content && <div className={c('content')}>
        <ContentRenderer content={content} />
        <div className={c("button")}>
          <Button buttonStyle={ButtonStyle.BUTTON} href="/collections">{"See more"}</Button>
        </div>
      </div>}
    </Page>
  );
}