import React from "react";
import Config from "../../models/Config";
import Content from "../../models/Content";
import { withConfig } from "../ConfigContext";
import { getReferencedContent, getRendererFromContent, Renderer, Renderers, withRenderers } from "../RenderersContext";

interface ContentRendererProps {
  config: Config;
  content: Content;
  renderers: Renderers;
  renderer?: Renderer;
}

function ContentRenderer({
  config,
  content,
  renderers,
  renderer
}: ContentRendererProps) {
  if (Array.isArray(content)) {
    return (
      <>
        {content.map(_content =>
          <ContentRenderer 
            content={_content}
            config={config}
            renderers={renderers}
            renderer={renderer}
          />
        )}
      </>
    );
  }

  const referencedContent = getReferencedContent(content, config);
  const Renderer = renderer 
    ? renderer 
    : getRendererFromContent(referencedContent, renderers);

  return (
    <Renderer {...referencedContent} />
  );
}

export default withConfig(withRenderers(ContentRenderer));
