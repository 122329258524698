import React from 'react';
import getScopedClassName from '../../utils/getScopedClassName';
import Markdown from '../Markdown';
import "./style.scss";

interface IntroProps {
  children: string;
}

const c = getScopedClassName('Intro');

export default function Intro({
  children
}: IntroProps) {
  return (
    <div className={c('root')}>
      <Markdown>{children}</Markdown>
    </div>
  );
}
