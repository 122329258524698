import React, { Component, createRef, ReactNode } from "react";
import getScopedClassName from "../../utils/getScopedClassName";
import onClickInside from "../../utils/onClickInside";
import './style.scss';

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
}

interface ModalState {
  isCloseable: boolean;
}

const c = getScopedClassName("Modal");

export default class Modal extends Component<ModalProps, ModalState> {

  private contents;

  constructor(props: ModalProps) {
    super(props);

    this.contents = createRef();
    this.state = {
      isCloseable: this.props.isOpen
    }
  }

  componentDidMount() {
    onClickInside(this.contents.current, () => {
      if (this.state.isCloseable) {
        this.props.onClose();
      }
    });
  }

  componentDidUpdate(prevProps: ModalProps) {
    const { isOpen } = this.props;

    if (prevProps.isOpen !== isOpen && isOpen) {
      this.setState({ isCloseable: false }, () => {
        setTimeout(() => {
          this.setState({ isCloseable: true });
        }, 300);
      });
    } 
  }

  render() {
    const { children, isOpen } = this.props;

    return (
      <div className={`${c("root")} ${isOpen ? c('open') : ''}`}>
        {children}
      </div>
    );
  }
}