import markdown from 'marked';
import xss from 'xss';

const Xss: any = xss;

const bodySanitizer = new Xss.FilterXSS({
  stripIgnoreTag: true,
  whiteList: {
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    a: ['href', 'title', 'target'],
    em: [],
    i: [],
    strong: [],
    b: [],
    br: [],
    p: [],
    span: [],
    ul: [],
    ol: [],
    li: [],
    code: ['class'],
    pre: ['class']
  }
});

export default function getSafeHtml(html: string) {
  return bodySanitizer.process(markdown(html));
};
