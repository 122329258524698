/**
 * Register a callback to fire when a click happens inside a given element
 * @param element 
 * @param callback 
 * @return listener removal function
 */
export default function onClickInside(element: HTMLElement, callback: (boolean) => any): () => void {
  const clickEventKey = 'click';
  const clickListener = (e: MouseEvent) => {
    const path = e.composedPath();
    if (Array.isArray(path) && path.indexOf(element) > -1) {
      callback(true);
    } else {
      callback(false);
    }
  };
  window.document.addEventListener(clickEventKey, clickListener);
  return () => window.document.removeEventListener(clickEventKey, clickListener);
}
