import React, { createContext, ReactNode } from "react";
import { withConfig } from "../../../lib/components/ConfigContext";
import Config from "../../../lib/models/Config";

export const PriceCoefficientContext = createContext(0);

export function withPriceCoefficient(WrappedComponent) {
  return (
    <PriceCoefficientContext.Consumer>
      {(priceCoefficient) =>  <WrappedComponent priceCoefficient={priceCoefficient} />}
    </PriceCoefficientContext.Consumer>
  )
}

interface PriceCoefficientProviderProps {
  config: Config;
  children: ReactNode;
}

function PriceCoefficientProvider({
  config,
  children
}: PriceCoefficientProviderProps) {
  console.log(config);
  const priceCoefficient = config?.metadata?.priceCoefficient || 0;

  return (
    <PriceCoefficientContext.Provider value={priceCoefficient}>
      {children}
    </PriceCoefficientContext.Provider>
  )
}

export default withConfig(PriceCoefficientProvider);
