import React, { ReactNode } from "react";
import getScopedClassName from "../../../lib/utils/getScopedClassName";
import "./style.scss";

interface LogoProps {
  href: string;
  children: ReactNode;
}

const c = getScopedClassName('Logo');

export default function Logo({
  href,
  children
}: LogoProps) {
  return (
    <a className={c('root')} href={href}>
      {children}
    </a>
  )
}