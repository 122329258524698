import React, { ReactNode } from 'react';
import Heading, { HeadingLevel, HeadingStyle } from '../../../lib/components/Heading';
import Html from '../../../lib/components/Markdown';
import Content from '../../../lib/models/Content';
import getScopedClassName from '../../../lib/utils/getScopedClassName';
import resolveImageSrc from '../../utils/resolveImageSrc';
import Intro from '../Intro';
import "./style.scss";

interface PageProps extends Content {
  children?: ReactNode;
}

const c = getScopedClassName('Page');

export default function Page({
  body,
  children,
  description,
  title,
  imageUrl
}: PageProps) {
  const resolvedSrc = resolveImageSrc(imageUrl);

  return (
    <div className={c('root')}>
      {(title || description) && <header className={c('header')}>
        {title && <div className={c('title')}>
          <Heading 
            level={HeadingLevel.H1}
            style={HeadingStyle.PRIMARY}
          >
            {title}
          </Heading>
        </div>}
        {description && <div className={c('description')}>
          <Intro>{description}</Intro>
        </div>}
      </header>}
      {body && <section className={c('body')}><Html>{body}</Html></section>}
      {imageUrl && <div className={c('image')}>
        <img className={c('image-content')} src={resolvedSrc} /> 
      </div>}
      {children}
    </div>
  )
}