import React from "react";
import Content from "../../../lib/models/Content";
import getScopedClassName from "../../../lib/utils/getScopedClassName";
import ContentRenderer from "../../../lib/components/ContentRenderer";
import Page from "../../../lib/components/Page";
import './style.scss';
import CollectionCard from "../CollectionCard";

interface PageCollectionsProps extends Content {}

const c = getScopedClassName("PageCollections");

export default function PageCollections({
  description,
  title,
  content,
  body
}: PageCollectionsProps) {
  return (
    <Page
      description={description}
      title={title}
      body={body}
    >
      <ul className={c("collections")}>
        <ContentRenderer 
          renderer={CollectionCard} 
          content={content}
        />
      </ul>
    </Page>
  );
}