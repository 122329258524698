import React from "react";
import Link from "../../../lib/models/Link";
import Button, { ButtonStyle } from "../../../lib/components/Button";

export default function MenuLink({
  title,
  route
}: Link) {
  return (
    <Button 
      buttonStyle={ButtonStyle.LINK}
      href={route}
    >
      {title}
    </Button>
  )
}
